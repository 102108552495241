import React, {useState} from 'react';
import { useHistory, Link } from "react-router-dom";
import { Form, Button, FormControl, Spinner } from "react-bootstrap";
import CardLayout from "../components/CardLayout.js";
import InputGroup from 'react-bootstrap/InputGroup';
import PhoneInput from "react-phone-input-2";
import { useClient }from "../Client.js";
import '../components/react-phone-input-2.css';


export default function Register (props) {

  let history = useHistory();

  const [passwordHide, setPasswordHide] = useState(1);
  function togglePasswordHide () {
    setPasswordHide(!passwordHide);
  }

  const [tosIsChecked, setTosIsChecked] = useState(null);
  function checkTosIsChecked (e) {
    setTosIsChecked(!e.target.checked);
  }

  const [phone, setPhone] = useState('');

  const [submitSpinnerToggle, setSubmitSpinnerToggle] = useState(0);

  const client = useClient();
  function formSubmit (e) {
    e.preventDefault();
    setSubmitSpinnerToggle(true);
    client({
      url:'/register',
      method:'post',
      data: new FormData(e.target) })
      .then(response => {
        history.push('/RegisterSuccess');
      })
      .catch((error) => {
        setSubmitSpinnerToggle(false);
      });
  }




  return (
    <CardLayout title="Crea il tuo Account">
        <Form onSubmit={formSubmit}>
          <Form.Group>
            <Form.Label>Il tuo nome</Form.Label>
            <Form.Control name="name" type="text" placeholder="Compila questo campo" required/>
          </Form.Group>
          <Form.Group>
            <Form.Label>La tua email</Form.Label>
            <Form.Control name="email" type="email" placeholder="Compila questo campo" required/>
          </Form.Group>
          <Form.Group controlId="formPhone">
            <Form.Label>Il tuo numero di telefono</Form.Label>
            <Form.Control name="phone" type="hidden" value={phone}/>
            <PhoneInput
            inputProps={{
              required: true,
              pattern:".{5,}"
            }}
            localization={{it: 'Italia'}}
            placeholder=""
            specialLabel={false}
            countryCodeEditable={false}
            enableLongNumbers={true}
            country={'it'}
            value={phone}
            onChange={setPhone}
            />
          </Form.Group>
          <Form.Group controlId="formPassword">
            <Form.Label>Imposta una password</Form.Label>
            <InputGroup className="mb-3">
              <FormControl name="password" required type={passwordHide?'password':"text"} placeholder="Compila questo campo" />
              <InputGroup.Append>
              <Button variant="light" onClick={togglePasswordHide}><i className={passwordHide?"far fa-eye-slash fa-fw":"far fa-eye fa-fw"}></i></Button>
            </InputGroup.Append>
          </InputGroup>

          </Form.Group>
          <Form.Group controlId="formTos">
            <Form.Check type="checkbox" >
              <Form.Check.Input type="checkbox" required isInvalid={tosIsChecked} onChange={checkTosIsChecked}/>
              <Form.Check.Label className="small">Confermo di aver letto e accettato i <Link to="/legal#tos" target="_blank">termini di servizio</Link> e la politica sul <Link to="/legal#tdp" target="_blank">trattamento dei dati personali</Link> e sull'<Link to="/legal#cp" target="_blank">utilizzo dei cookies</Link>.</Form.Check.Label>
            </Form.Check>
          </Form.Group>
          <div className="d-flex justify-content-between">
          <Button variant="link" as={Link} to="/login">Accedi</Button>
          <Button variant="primary" type="submit" disabled={submitSpinnerToggle}>
          Avanti
          <Spinner className={"ml-2 submitSpinner "+(submitSpinnerToggle?"show":"hide")} as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
          </Button>
          </div>
        </Form>
    </CardLayout>
  );
}
