import React from 'react';
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import CardLayout from "../components/CardLayout.js";


export default function RegisterSuccass (props) {

  return (
    <CardLayout title="Nuovo account creato">
        <p>
          Clicca sul link che ti abbiamo inviato all'indirizzo di posta elettronica che hai indicato per completare la registrazione.
        </p>
        <Button as={Link} to="/login" variant="primary" type="submit">
          Accedi
        </Button>
    </CardLayout>
  );
}
