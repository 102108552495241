import { BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute.js";
import Loadable from "./components/Loadable.js";
import Status401 from "./views/Status401.js";
import Error from "./views/Error.js";
import Loading from "./views/Loading.js";

import Register from "./views/Register.js";
import RegisterSuccess from "./views/RegisterSuccess.js";
import Activation from "./views/Activation.js";
import Login from "./views/Login.js";
import Reminder from "./views/Reminder.js";
import PasswordReset from "./views/PasswordReset.js";
import Legal from "./views/Legal.js";
import LogoutHappens from "./views/LogoutHappens.js";
import Private from "./Private.js";
import Notifier from "./components/Notifier.js";

export default function App() {
  return (
    <Router>
      <Switch>
        <Route path="/register" component={Register} />
        <Route path="/registerSuccess" component={RegisterSuccess} />
        <Route path="/login" component={Login} />
        <Route path="/reminder" component={Reminder} />
        <Route path="/passwordReset" component={PasswordReset} />
        <Route path="/activation" component={Activation} />
        <Route path="/legal" component={Legal} />
        <Route path="/logoutHappens" component={LogoutHappens} />
        <Route path="/status401" component={Status401} />
        <PrivateRoute path="/private" component={Private} />
        <Route exact path="/" component={() => {return (
          <Loadable config={{url:"/user/current",method:'get'}}>
            {(response,error,loading) => {
              if (error) {
                if (error.response) {
                  if (error.response.status) {
                    if (error.response.status === 401) return (<Redirect to="/login" />);
                    if (error.response.data && error.response.data.message) {
                      return (<Error title={error.response.status+" "+error.response.statusText} message={error.response.data.message}/>)
                    } else return (<Error title={error.response.status+" "+error.response.statusText}/>)
                  }
                }
                return (<Error title="Connessione non disponibile" message="Verifica che il tuo dispositivo sia ancora connesso"/>)
              }
              else if (loading) return (<Loading/>);
              else if (response !== null) return (<Redirect to="/private" />);
              else return (<div/>);
            }}
          </Loadable>
          )}} />
        <Route exact path="*" render={() =><Error title="Pagina non trovata"/>} />
      </Switch>
      <Notifier/>
    </Router>
  );
}
