import React, {useContext,useState} from 'react';
import { Card, Button,Spinner } from "react-bootstrap";
import UserContext from '../components/UserContext.js';
import { Link,useHistory } from "react-router-dom";
import { useClient, APIUrl }from "../Client.js";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';

export default function DossierCard (props) {
  const user = useContext(UserContext);
  const [submitSpinnerToggle, setSubmitSpinnerToggle] = useState(0);
  const client = useClient();
  const history = useHistory();
  const actionDelete = () => {
    setSubmitSpinnerToggle(true);
    client({
      url:'/dossier/'+props.data.id+'/delete',
      method:'post',
      })
      .then(response => {
        history.go(0);
      })
      .finally(() => {
        setSubmitSpinnerToggle(false);
      });
  }
  return (
    <Card>
      <Card.Body>
        {props.data.title != null && (<Card.Title>{props.data.title}</Card.Title>)}
        {props.data.description != null && (<Card.Text>{props.data.description}</Card.Text>)}
        <div className="d-flex justify-content-between">
          {user.permissions['dossier_delete'] === true && (<Button disabled={submitSpinnerToggle} variant="light" onClick={actionDelete}>Elimina <FontAwesomeIcon icon={faTrash} /><Spinner className={"ml-2 submitSpinner "+(submitSpinnerToggle?"show":"hide")} as="span" animation="border" size="sm" role="status" aria-hidden="true"/></Button>)} {props.data.attachment != null && (<Button className="ml-auto" variant="light" href={APIUrl(props.data.attachment)} target="_blank">Scarica <FontAwesomeIcon icon={faFileDownload} /></Button>)}
        </div>
      </Card.Body>
      {props.data.image != null && (<Card.Img variant="bottom" src={APIUrl(props.data.image)} />)}
    </Card>
  );
}
