import { React } from 'react';
import { Alert } from "react-bootstrap";
import { Store } from "pullstate";
import './Notifier.css';

const NotifierStore = new Store({ list: [] });
const Notifier = props => {
  const list = NotifierStore.useState(s => s.list);

  return (
    <div className="Notifier">
    {list.map((entry, i) => {
         return (<Notification variant={entry.variant} key={i} controlKey={i} >{entry.message}</Notification>)
      })}
    </div>
  );
}
const Notification = props => {
  const destroyer = useNotifierDestroyer();
  const destroy = () => destroyer(props.controlKey);
  return ( <Alert transition={false} className="Notification" variant={props.variant} onClose={destroy} dismissible>{props.children}</Alert> );
}

const useNotifier = () => {
  return function (message,variant = 'warning') {
    NotifierStore.update(s => {
      s.list.push({"message":message,"variant":variant});
    })
  }
}
const useNotifierDestroyer = () => {
  return function (controlKey) {
    NotifierStore.update(s => {
      s.list.splice(controlKey,1);
    })
  }
}
export default Notifier;
export { Notifier , NotifierStore, useNotifier };
