import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import Logo from '../components/Logo.js';

export default function BlankLayout (props) {

  return (
    <Container className="BlankLayout">
      <Row>
        <Col className="mx-auto">
          <Logo height="36" className="mb-3 mt-3" alt="logo"/>
          <h4>{props.title}</h4>
          {props.children}
        </Col>
      </Row>
    </Container>
  );
}
