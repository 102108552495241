import React from "react";
import { Route } from "react-router-dom";
import Loadable from "./Loadable.js";
import Status401 from "../views/Status401.js";
import Error from "../views/Error.js";
import Loading from "../views/Loading.js";
import UserContext from "./UserContext.js";

const PrivateRoute = props => {
  return (
    <Loadable config={{url:"/user/current",method:'get'}}>
      {(response,error,loading) => {
        if (error) {
          if (error.response) {
            if (error.response.status) {
              if (error.response.status === 401) return (<Status401/>);
              if (error.response.data && error.response.data.message) {
                return (<Error title={error.response.status+" "+error.response.statusText} message={error.response.data.message}/>)
              } else return (<Error title={error.response.status+" "+error.response.statusText}/>)
            }
          }
          return (<Error title="Connessione non disponibile" message="Verifica che il tuo dispositivo sia ancora connesso"/>)
        }
        else if (loading) return (<Loading/>);
        else if (response !== null) return (
          <UserContext.Provider value={response.data}>
            <Route {...props}/>
          </UserContext.Provider>
        );
        else return (<div/>);
      }}
    </Loadable>
    );
/*  else return (
      <Redirect to='/status401' />
    );*/
};

export default PrivateRoute;
