import React from 'react';
import initials from 'initials';
import './Avatar.css';

function stringToHslColor(str, s, l) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let h = hash % 360;
  return 'hsl('+h+', '+s+'%, '+l+'%)';
}
function unitsParser(str) {
  let propRe = /^([+-]?(?:\d+|\d*\.\d+))([a-z]*|%)$/
  return str.match(propRe);
}
export default function Avatar (props) {
  let opt = {
    name: "Guest User",
    textRatio: .5,
    className: "",
    withLabel: true,
    imageStyle: {width: '1.3rem' }
  };
  Object.assign(opt,props);
  Object.assign(opt.imageStyle,{ background: stringToHslColor(opt.name,60,60),  })
  const initialsStyle = { fontSize: (unitsParser(opt.imageStyle.width)[1]*0.9*(1/initials(opt.name).length))+unitsParser(opt.imageStyle.width)[2] };

  return (
    <div className={"Avatar "+opt.className}>
      <div className="Avatar_image" style={opt.imageStyle} >
        <div className="Avatar_initials" style={initialsStyle}>{initials(opt.name)}</div>
      </div>
      <div className="Avatar_label" >{opt.name}</div>
    </div>
  );
}
