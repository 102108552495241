import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import PrivateLayout from "./components/PrivateLayout.js";
import DossierList from "./views/DossierList.js";
import Login from "./views/Login.js";
import Error from "./views/Error.js";

export default function Private() {
  let { path, url } = useRouteMatch();
  return (
      <PrivateLayout>
        <Switch>
          <Route exact path={path} render={() => <Redirect to={`${url}/dossierList`} />} />
          <Route exact path={`${path}/dossierList`} component={DossierList} />
          <Route exact path={`${path}/test`} component={Login} />
          <Route exact path="*" render={() =><Error title="Pagina non trovata"/>} />
        </Switch>
      </PrivateLayout>
  );
}
