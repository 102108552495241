import React, { useState,useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import { Form, Button, FormControl, Spinner } from "react-bootstrap";
import InputGroup from 'react-bootstrap/InputGroup';
import CardLayout from "../components/CardLayout.js";
import { useClient } from "../Client.js";
import { useNotifier } from "../components/Notifier.js";

export default function Activation (props) {
  const [submitSpinnerToggle, setSubmitSpinnerToggle] = useState(0);
  const [code, setCode] = useState('');

  const location = useLocation();


  const [passwordHide, setPasswordHide] = useState(1);
  const togglePasswordHide = function (e) {
    setPasswordHide(!passwordHide);
  };
  const client = useClient();
  const notifier = useNotifier();
  function formSubmit (e) {
    e.preventDefault();
    setSubmitSpinnerToggle(true);
    client({
      url:'/passwordReset',
      method:'post',
      data: new FormData(e.target) })
      .then(response => {
        notifier("La password è stata modificata.","success");
      })
      .finally(error => {
        setSubmitSpinnerToggle(false);
      });
  }

  useEffect(() => {
    let query = new URLSearchParams(location.search);
    setCode(query.get("code") !== null?query.get("code"):"");
  }, [location]);

  return (
    <CardLayout title="Reimposta password">
      <Form onSubmit={formSubmit}>
        <Form.Group controlId="formEmail">
          <Form.Label>Conferma la tua email</Form.Label>
          <Form.Control name="email" type="email" placeholder="Compila questo campo" required/>
        </Form.Group>
        <Form.Control name="code" type="hidden" value={code}/>
        <Form.Group controlId="formPassword">
          <Form.Label>Imposta una nuova password</Form.Label>
          <InputGroup className="mb-3">
            <FormControl name="password" required type={passwordHide?'password':"text"} placeholder="Compila questo campo" />
            <InputGroup.Append>
              <Button variant="light" onClick={togglePasswordHide}><i className={passwordHide?"far fa-eye-slash fa-fw":"far fa-eye fa-fw"}></i></Button>
            </InputGroup.Append>
          </InputGroup>
        </Form.Group>
        <div className="d-flex justify-content-between">
          <Button variant="link" as={Link} to="/login">Accedi</Button>
          <Button variant="primary" type="submit" disabled={submitSpinnerToggle}>
            Esegui
            <Spinner className={"ml-2 submitSpinner "+(submitSpinnerToggle?"show":"hide")} as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
          </Button>
        </div>
      </Form>
    </CardLayout>
  );
}
