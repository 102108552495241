import React, {useEffect,useContext,useState} from 'react';
import { Card, Form, Button, Spinner } from "react-bootstrap";
import bsCustomFileInput from 'bs-custom-file-input';
import UserContext from '../components/UserContext.js';
import { useHistory } from "react-router-dom";
import { useClient }from "../Client.js";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';

export default function DossierCardCreate (props) {
  const user = useContext(UserContext);
  const history = useHistory();
  const client = useClient();
  const [submitSpinnerToggle, setSubmitSpinnerToggle] = useState(0);
  const [imagePreview, setImagePreview] = useState(null);

  useEffect(()=>{
    bsCustomFileInput.init();
  },[]);

  function formSubmit (e) {
    e.preventDefault();
    setSubmitSpinnerToggle(true);
    client({
      url:'/dossier/create',
      method:'post',
      data: new FormData(e.target),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
      })
      .then(response => {
        history.go(0);
      })
      .finally(() => {
        setSubmitSpinnerToggle(false);
      });
  }
  const isImage = (file) => file['type'].includes('image');
  function imageChange(e) {
    if (e.target.files[0] && isImage(e.target.files[0])) {
      setImagePreview(URL.createObjectURL(e.target.files[0]));
    } else setImagePreview(null);
  }

  if (!user.permissions['dossier_create']) return null;
  return (
    <Card>
      <Form onSubmit={formSubmit}>
        <Card.Body>
          <Card.Title>Aggiungi un nuovo dossier</Card.Title>
          <Form.Group controlId="formCardTitle">
            <Form.Label className="text-muted small mb-0">Titolo</Form.Label>
            <Form.Control name="title" type="text" placeholder="" />
          </Form.Group>
          <Form.Group controlId="formCardDesc">
            <Form.Label className="text-muted small mb-0">Descrizione</Form.Label>
            <Form.Control name="description" as="textarea" placeholder="" />
          </Form.Group>
          <Form.Group controlId="formCardImage">
            <Form.Label className="text-muted small mb-0">Copertina (600x300)</Form.Label>
            <Form.File name="image" id="custom-file" label="" data-browse="Carica" custom onChange={imageChange} accept="image/*"/>
          </Form.Group>
          <Form.Group controlId="formCardAttach">
            <Form.Label className="text-muted small mb-0">Allegato</Form.Label>
            <Form.File name="attachment" id="custom-file" label="" data-browse="Carica" custom />
          </Form.Group>
          <div className="d-flex justify-content-between">
            <Button disabled={submitSpinnerToggle} variant="primary" type="submit" className="ml-auto">Pubblica<FontAwesomeIcon className="ml-2" icon={faSave} /><Spinner className={"ml-2 submitSpinner "+(submitSpinnerToggle?"show":"hide")} as="span" animation="border" size="sm" role="status" aria-hidden="true"/></Button>
          </div>
        </Card.Body>
        {imagePreview !== null && (<Card.Img variant="bottom" src={imagePreview} />)}
      </Form>
    </Card>
  );
}
