import React from "react";
import { Alert } from "react-bootstrap";
import Loadable from "./Loadable.js";
import Loading from "../views/Loading.js";

const LoadableBlock = props => {
  return (
    <Loadable config={props.config}>
      {(response,error,loading) => {
        if (error) {
          if (error.response ) {
            if (error.response.status) {
              if (error.response.data && error.response.data.message) {
                return (<Alert variant="warning"><b>{error.response.status+" "+error.response.statusText+": "}</b>{error.response.data.message}</Alert>);
              } else return (<Alert variant="warning"><b>{error.response.status+" "+error.response.statusText}</b></Alert>);
            }
          }
          return (<Alert variant="info"><b>Connessione non disponibile:</b> Verifica che il tuo dispositivo sia ancora connesso</Alert>)
        }
        else if (loading) return (<Loading/>);
        else if (response !== null) return props.children(response);
        else return (<div/>);
      }}
    </Loadable>
    );
/*  else return (
      <Redirect to='/status401' />
    );*/
};

export default LoadableBlock;
