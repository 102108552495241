import React from 'react';
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import CardLayout from "../components/CardLayout.js";


export default function HTTPError (props) {
  let opts = {
    title: "Errore",
    message: ""
  };
  Object.assign(opts,props);

  let history = useHistory();

  return (
    <CardLayout title={opts.title}>
        <p>
          {opts.message}
        </p>
        <div className="d-flex justify-content-between">
        <Button onClick={()=>{ history.goBack() }} variant="primary">
          Indietro
        </Button>
        <Button onClick={()=>{ history.go(0) }} variant="primary">
          Ricarica
        </Button>
        </div>
    </CardLayout>
  );
}
