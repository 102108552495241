import React from 'react';
import { Container, Row, Col, Card } from "react-bootstrap";
import Logo from '../components/Logo.js';
import './CardLayout.css';


export default function CardLayout (props) {

  return (
    <Container className="CardLayout">
      <Row>
        <Col sm="11" md="8" lg="6" xl="6" className="mx-auto">
          <Card>
          <Card.Body>
          <Logo height="36" className="mb-3" alt="logo"/>
          <h4>{props.title}</h4>
          {props.children}
          </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
