import React from 'react';
import { Container } from "react-bootstrap";
import AppNavbar from './AppNavbar.js';
import './PrivateLayout.css';


export default function PrivateLayout (props) {

  return (
    <div>
      <AppNavbar/>
      <Container fluid>
          {props.children}
      </Container>
    </div>
  );
}
