import React from 'react';
import { Link } from "react-router-dom";
import logo from '../logo.svg';
import './Logo.css';


export default function Logo (props) {
  let to = (props.to !== undefined)?props.to:"/";
  let height = (props.height !== undefined)?props.height:"36";
  return (
      <Link to={to} className={"logo "+props.className}>
          <img height={height} src={logo} alt="logo"/>
      </Link>
  );
}
