import { useState, useEffect } from 'react';
import { useClient } from '../Client.js'

const Loadable = props => {
    const children = props.children;
    const config = props.config;
    const [loading,setLoading] = useState(false);
    const [error,setError] = useState(null);
    const [response,setResponse] = useState(null);
    const client =  useClient(false);
    useEffect(() => {
      if (config) {
        setLoading(true);
        client(config)
          .then(function (response) {
            setResponse(response);
            setLoading(false);
          })
          .catch(function (error) {
            setError(error);
            setLoading(false);
          });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return children(response,error,loading);

};

export default Loadable;
