import axios from 'axios';
import { useNotifier } from "./components/Notifier.js";

const ClientConfig = {
  baseURL: 'https://api.keycapital.it',
  mode: 'no-cors',
  headers: {
    'Access-Control-Allow-Origin': 'http://api.keycapital.it',
    'Content-Type': 'application/json',
  },
  withCredentials: true,
  credentials: 'same-origin',
}

const Client = axios.create(ClientConfig);

const useClient = (withDefaultErrorHandler = true) => {
    const errorHandler = useClientErrorHandler();

    if (withDefaultErrorHandler) return (config) => {
      return Client.request(config).catch(errorHandler)
    }
    else return (config) => {
      return Client.request(config)
    }
}

const APIUrl = (url) => ClientConfig.baseURL+url;

const useClientErrorHandler = () => {
  let notifier = useNotifier();
  return function (error) {
    if (error.response) {
      if (error.response.status) {
        if (error.response.status === 401) notifier("Permessi insufficienti","danger");
        if (error.response.data && error.response.data.message) {
          notifier(error.response.data.message);
        } else notifier(error.response.status+" "+error.response.statusText);
      }
    }
    else notifier("Connessione non disponibile. Verifica che il tuo dispositivo sia ancora connesso.","info");
    return Promise.reject(error);
  }
}
export default Client;
export { Client, ClientConfig, useClientErrorHandler, useClient, APIUrl };
