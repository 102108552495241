import React, { useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import { Form, Button, FormControl, Spinner } from "react-bootstrap";
import InputGroup from 'react-bootstrap/InputGroup';
import CardLayout from "../components/CardLayout.js";
import { useClient } from "../Client.js";

export default function Login (props) {
  let history = useHistory();
  const [passwordHide, setPasswordHide] = useState(1);
  const togglePasswordHide = function (e) {
    setPasswordHide(!passwordHide);
  };

  const [submitSpinnerToggle, setSubmitSpinnerToggle] = useState(0);

  const client = useClient();

  function formSubmit (e) {
    e.preventDefault();
    setSubmitSpinnerToggle(true);
    client({
      url:'/login',
      method:'post',
      data: new FormData(e.target) })
      .then(response => {
        history.push('/private');
      })
      .finally(() => {
        setSubmitSpinnerToggle(false);
      });
  }

  return (
    <CardLayout title="Accedi">
        <Form onSubmit={formSubmit}>
          <Form.Group controlId="loginEmail">
            <Form.Label>La tua email</Form.Label>
            <Form.Control name="email" type="email" placeholder="Compila questo campo" required/>
          </Form.Group>
          <Form.Group controlId="loginPassword">
            <Form.Label>La tua password <span className="small">(<Link to="/reminder">dimenticata?</Link>)</span></Form.Label>
            <InputGroup className="mb-3">
              <FormControl name="password" required type={passwordHide?'password':"text"} placeholder="Compila questo campo" />
              <InputGroup.Append>
                <Button variant="light" onClick={togglePasswordHide}><i className={passwordHide?"far fa-eye-slash fa-fw":"far fa-eye fa-fw"}></i></Button>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
          <div className="d-flex justify-content-between">
            <Button variant="link" as={Link} to="/register">Registrati</Button>
            <Button variant="primary" type="submit" disabled={submitSpinnerToggle}>
              Accedi
              <Spinner className={"ml-2 submitSpinner "+(submitSpinnerToggle?"show":"hide")} as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
            </Button>
          </div>
        </Form>
    </CardLayout>
  );
}
