import React from 'react';
import { Spinner } from "react-bootstrap";
import "./Loading.css";


export default function Loading (props) {
  return (
    <div className="Loading">
      <Spinner animation="grow" className="spinner" variant="info"/>
    </div>
  );
}
