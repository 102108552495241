import {React, useContext} from 'react';
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";
import Logo from '../logo.svg';
import Avatar from './Avatar.js';
import { useClient } from "../Client.js";
import UserContext from "./UserContext.js";
import './AppNavbar.css'

export default function AppNavbar (props) {
  let history = useHistory();
  const user = useContext(UserContext);
  const client = useClient();
  const logOutAction = (e) => {
    e.preventDefault();
    client({url:"/logout",method:'get'})
      .then(response => {
        history.push('/LogoutHappens');
      });
  }
  return (
    <Navbar bg="light" expand="lg" className="mb-3">
      <Navbar.Brand as={Link} to="/">
        <img
          src={Logo}
          height="30"
          className="d-inline-block align-top"
          alt="logo"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link as={Link} to="/private/dossierList">Dossier</Nav.Link>
        </Nav>
        <Nav>
          <NavDropdown className="UserDropdown" alignRight title={(<Avatar name={user.name} maxInitials={2} />)} >
            <NavDropdown.Item onClick={logOutAction} as="button">Chiudi sessione</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
