import React from 'react';
import Loadable from "../components/Loadable.js";
import { useLocation } from "react-router-dom";
import { Alert, Spinner } from "react-bootstrap";
import CardLayout from "../components/CardLayout.js";

export default function Activation (props) {

  const location = useLocation();
  let query = new URLSearchParams(location.search);

  return (
    <CardLayout title="Verifica indirizzo email">
      <Loadable config={{ url:'/activation', method:'post', data: { code: query.get("code"), id: query.get("id")} }}>
        {(response,error,loading) => {
          if (error) {
            if (error.response) {
                if (error.response.data && error.response.data.message) {
                  return (<Alert variant="warning" transition={false}>{error.response.data.message}</Alert>)
                } else return (<Alert variant="warning" transition={false}>Impossibile completare la richiesta</Alert>)
            }
            return (<Alert variant="warning" transition={false}>Impossibile completare la richiesta</Alert>)
          }
          else if (loading) return (<span>Verifica indirizzo in corso <Spinner className="ml-2 submitSpinner" as="span" animation="border" size="sm"/></span>);
          else if (response !== null) return (<Alert variant="success" transition={false}> Il tuo indirizzo è stato verificato.</Alert>);
          else return (<div/>);
        }}
      </Loadable>
    </CardLayout>
  );
}
