import { Row,Col,CardColumns} from "react-bootstrap";
import LoadableBlock from '../components/LoadableBlock.js';
import DossierCardCreate from '../components/DossierCardCreate.js';
import DossierCard from '../components/DossierCard.js';
import './DossierList.css';

export default function DossierList() {
  return (
    <div className="DossierList">
      <Row>
        <Col>
          <h4>Dossier disponibili</h4>
        </Col>
      </Row>
        <LoadableBlock config={{url:"/dossier",method:'get'}}>
            {(response) => (
              <CardColumns>
              <DossierCardCreate/>
              {response.data.map((card, i) => ( <DossierCard key={i} data={card} /> ) ) }
              </CardColumns>
            )}

        </LoadableBlock>

    </div>
  );
}
