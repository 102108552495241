import React, {useState} from 'react';
import { Link } from "react-router-dom";
import { Form, Button, Spinner } from "react-bootstrap";
import CardLayout from "../components/CardLayout.js";
import { useNotifier } from "../components/Notifier.js";
import { useClient }from "../Client.js";


export default function Register (props) {

  const [submitSpinnerToggle, setSubmitSpinnerToggle] = useState(0);
  const client = useClient();
  const notifier = useNotifier();
  function formSubmit (e) {
    e.preventDefault();
    setSubmitSpinnerToggle(true);
    client({
      url:'/reminder',
      method:'post',
      data: new FormData(e.target) })
      .then(response => {
        notifier("Abbiamo inviato le istruzioni per resettare la password all'indirizzo indicato. Controlla la tua email.","success");
      })
      .finally(error => {
        setSubmitSpinnerToggle(false);
      });
  }

  return (
    <CardLayout title="Recupero Password">
      <Form onSubmit={formSubmit}>
        <Form.Group>
          <Form.Label>La tua email</Form.Label>
          <Form.Control name="email" type="email" placeholder="Compila questo campo" required/>
        </Form.Group>

        <div className="d-flex justify-content-between">
        <Button variant="link" as={Link} to="/login">Accedi</Button>
        <Button variant="primary" type="submit" disabled={submitSpinnerToggle}>
        Esegui
        <Spinner className={"ml-2 submitSpinner "+(submitSpinnerToggle?"show":"hide")} as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
        </Button>
        </div>
      </Form>
    </CardLayout>
  );
}
